import { Component, OnInit , Inject} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { SsoService, Account, LoginResult } from '../sso.service'

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.css']
})
export class SsoLoginComponent implements OnInit {

  account: Account
  loginResult: LoginResult
  loginFailed: string = null

  constructor(
    private activatedRoute: ActivatedRoute,
    private ssoService: SsoService,
    private dialogRef: MatDialogRef<SsoLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.account = data.account
  }

  ngOnInit() {
  }

  login(mfa: string) {
    var queryParams = this.activatedRoute.snapshot.queryParams
    console.log("Login MFA", this.account.key, mfa)
    this.ssoService.login(this.account.key, mfa)
      .then(result => this.loginResult = result)
      .then(function (loginResult) {if ((!!queryParams)
                                        && ('loginAction' in queryParams)
                                        && (queryParams['loginAction'] == 'console')) {
        console.log("Immediately launching web console per loginAction");
        window.open(loginResult.ConsoleURL, '_blank')
      }})
      .catch(e => this.loginFailed = e.payload.errorMessage)
  }

  launchConsole() {
    console.log("Launch console")
    window.open(this.loginResult.ConsoleURL, '_blank')
  }

}
