import { Injectable } from '@angular/core';
import { AuthService } from './auth.service'

import * as Lambda from 'aws-sdk/clients/lambda'

const LAMBDA_NAME = "sso-prod-server"
const SSO_REGION = "us-east-1"

export type Account = {key: string, name: string, externalId: string, tags: string[]}
export type AccountList = {[key: string]: {key: string, name: string, externalId: string, tags: string[]}}
export type LoginResult = {AccessKeyId: string, SecretAccessKey: string, SessionToken: string, Expiration: Date, ConsoleURL: string, Account: Account}

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  private _accountsCache: AccountList

  constructor(private authService: AuthService) { }

  async list() : Promise<AccountList> {
    if (this._accountsCache)
      return this._accountsCache

    let response = await this.invoke('list_accounts', null, true)

    this._accountsCache = response.accounts

    return response.accounts
  }

  async login(key: string, mfa: string) : Promise<LoginResult> {
    let body = {
      account: key,
      mfa_token: mfa,
      include_console_url: true
    }

    let response = await this.invoke('login', body, true)

    response.Expiration = Date.parse(response.Expiration)

    return response
  }

  private async invoke(action: string, body: {} = {}, addCredentials: boolean = false) : Promise<any> {
    let credentials = this.authService.credentials
    let lambda = new Lambda({credentials, region: SSO_REGION})

    if (!body) {
      body = {}
    }

    if (addCredentials) {
      body = Object.assign({}, body, {
        aws_access_key_id: credentials.accessKeyId,
        aws_secret_access_key: credentials.secretAccessKey
      })
    }

    let payload = {action, ...body}

    let params = {
      FunctionName: LAMBDA_NAME,
      Payload: JSON.stringify(payload)
    }

    let response = await lambda.invoke(params).promise()
    let result = JSON.parse(response.Payload as string)

    if (response.FunctionError) {
      throw new SsoError(result)
    }

    return result
  }

}

export class SsoError extends Error {
  public payload: {}

  constructor(payload) {
    super(`Server error: ${payload.errorType} ${payload.errorMessage}`)
    this.payload = payload
  }
}
