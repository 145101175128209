import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'

import {
  MatToolbarModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatStepperModule,
  MatTableModule,
  MatSortModule,
  MatDialogModule
} from '@angular/material'

import { AppComponent } from './app.component'
import { AuthService } from './auth.service'
import { AuthGuardService } from './auth-guard.service'
import { SetupGuardService } from './setup-guard.service'

import { SsoService } from './sso.service'

import { NavigationComponent } from './navigation/navigation.component'
import { NotFoundComponent } from './not-found/not-found.component'
import { SetupComponent } from './setup/setup.component';
import { LoginComponent } from './login/login.component'
import { SsoListComponent } from './sso-list/sso-list.component'
import { SsoLoginComponent } from './sso-login/sso-login.component'

const appRoutes: Routes = [
  { path: 'setup', component: SetupComponent },
  { path: 'login', component: LoginComponent, canActivate: [SetupGuardService] },
  { path: '',      component: SsoListComponent, canActivate: [AuthGuardService] },
  { path: '**',    component: NotFoundComponent }
]

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    NotFoundComponent,
    SetupComponent,
    LoginComponent,
    SsoListComponent,
    SsoLoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false }
    ),
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    SetupGuardService,
    SsoService
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    SsoLoginComponent
  ]
})
export class AppModule { }
