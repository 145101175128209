import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class SetupGuardService implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate() {
    // console.log('SetupGuardService#canActivate called');

    if (this.authService.isConfigured)
      return true

    this.router.navigate(['/setup'], { replaceUrl:true });
    return false;
  }

}
