import { Injectable } from '@angular/core'
import { TextEncoder, TextDecoder } from 'text-encoding'
import * as AWS from 'aws-sdk/global'
import * as STS from 'aws-sdk/clients/sts'
import * as Base64 from 'base64-arraybuffer'
import {GetCallerIdentityResponse} from 'aws-sdk/clients/sts';

const ONICA_SSO = "onica-sso"
const ONICA_ACCOUNT = "363639951452"

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isLoggedIn: boolean = false
  private _isConfigured: boolean = false
  private _creds : AWS.Credentials

  constructor() {
    this._isConfigured = this.hasStoredCredentials()
  }

  get isLoggedIn(): boolean {
    return this._isLoggedIn
  }

  get isConfigured(): boolean {
    return this._isConfigured
  }

  get credentials() : AWS.Credentials {
    if (!this.isLoggedIn)
      throw new Error("Can't return credentials when not logged in")

    return this._creds
  }

  validateCredentials(credentials : AWS.Credentials) : Promise<boolean> {
    return this.validate(credentials.accessKeyId, credentials.secretAccessKey);
  }

  validate(accessKeyId: string, secretAccessKey: string) : Promise<boolean> {
    let sts = new STS({accessKeyId, secretAccessKey})
    return sts.getCallerIdentity().promise()
      .catch(() => { throw new Error("Invalid credentials") })
      .then((result: GetCallerIdentityResponse) => {
        if (result.Account !== ONICA_ACCOUNT)
          throw new Error("Wrong account credentials")

        return true;
      })
  }

  configure(accessKeyId: string, secretAccessKey: string, password: string) : Promise<boolean> {
    return this.validate(accessKeyId, secretAccessKey)
      .then(() => JSON.stringify({accessKeyId, secretAccessKey}))
      .then(creds => this.encrypt(creds, password))
      .then(creds => JSON.stringify(creds))
      .then(creds => {
        window.localStorage.setItem(ONICA_SSO, creds)
        return creds
      })
      .then(creds => this.loadFromString(creds, password))
      .then(() => this._isConfigured = true)
      .then(() => true)
  }

  login(password : string) : Promise<boolean> {
    return this.load(password)
  }

  clear() : void {
    window.localStorage.removeItem(ONICA_SSO)
    this._isLoggedIn = false
    this._isConfigured = false
    this._creds = null
  }

  private hasStoredCredentials() : boolean {
    return window.localStorage.getItem(ONICA_SSO) != null
  }

  private load(password : string) : Promise<boolean> {
    return this.loadFromString(window.localStorage.getItem(ONICA_SSO), password)
  }

  private loadFromString(creds : string, password : string) : Promise<boolean> {
    let c = JSON.parse(creds)

    return this.decrypt(c.encBuffer, c.iv, password)
      .catch(() => { throw new Error("Invalid password") })
      .then(pt => JSON.parse(pt))
      .then(obj => {
        let creds = new AWS.Credentials(obj.accessKeyId, obj.secretAccessKey)

        return this.validateCredentials(creds)
          .then(() => creds)
          .catch(() => { throw new Error("Password was correct, but the stored SSO credentials are invalid") })
      })
      .then(creds => {
        this._creds = creds
        this._isLoggedIn = true

        console.log("Logged in", this._creds)
      })
      .then(() => true)
  }

  private async keyFromPassword(password: string, alg: any): Promise<CryptoKey> {
    const pwUtf8 = new TextEncoder().encode(password);
    const pwHash = await crypto.subtle.digest('SHA-256', pwUtf8);
    return crypto.subtle.importKey('raw', pwHash, alg, false, ['encrypt', 'decrypt']);
  }

  private async encrypt(plainText: string, password: string) : Promise<{iv, encBuffer}> {
    const ptUtf8 = new TextEncoder().encode(plainText);

    const iv = crypto.getRandomValues(new Uint8Array(12));
    const alg = { name: 'AES-GCM', iv: iv };
    const key = await this.keyFromPassword(password, alg)

    return {
      iv: Base64.encode(iv),
      encBuffer: Base64.encode(await crypto.subtle.encrypt(alg, key, ptUtf8))
    };
  }

  private async decrypt(ctBuffer: string, iv: string, password: string) : Promise<string> {
    const pwUtf8 = new TextEncoder().encode(password);

    const alg = { name: 'AES-GCM', iv: Base64.decode(iv) };
    const key = await this.keyFromPassword(password, alg)

    const ptBuffer = await crypto.subtle.decrypt(alg, key, Base64.decode(ctBuffer));

    const plaintext = new TextDecoder().decode(ptBuffer);

    return plaintext;
  }

}
