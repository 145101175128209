import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service'

import { Observable, of, timer } from 'rxjs';
import { flatMap, map, catchError, delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      accessKeyId: ['', Validators.required],
      secretAccessKey: ['', Validators.required]
    },
    {
      asyncValidator: this.accessKeyValidator.bind(this)
    });

    this.secondFormGroup = this.formBuilder.group({
      password: ['', Validators.required]
    });
  }

  configure() {
    let accessKeyId = this.firstFormGroup.controls.accessKeyId.value
    let secretAccessKey = this.firstFormGroup.controls.secretAccessKey.value
    let password = this.secondFormGroup.controls.password.value

    this.authService.configure(accessKeyId, secretAccessKey, password)
      .then(() => {
        this.router.navigate(['/'], { replaceUrl:true })
      })
  }

  accessKeyValidator(group: FormGroup) : Observable<{}> {
    console.log("Called")

    return timer(500).pipe(
      tap(() => console.log("Processing")),

      flatMap(() => {
        let accessKeyId = group.controls.accessKeyId.value
        let secretAccessKey = group.controls.secretAccessKey.value

        return this.authService.validate(accessKeyId, secretAccessKey)
      }),

      map(res => {
        return null
      }),

      catchError((e,s) => {
        return of({ invalidCredentials: e.message })
      })

    )
  }

}
