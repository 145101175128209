import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService } from '../auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginFailed: string = null

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
  }

  login(password : string) {
    this.loginFailed = null
    let navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
      replaceUrl: true
    };

    this.authService.login(password)
      .then(() => this.router.navigate(['/'], navigationExtras))
      .catch(e => this.loginFailed = e)
  }

  clear() {
    this.authService.clear()
    this.router.navigate(['/setup'], { replaceUrl: true })
  }

}
