import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    // console.log('AuthGuardService#canActivate called');

    if (this.authService.isLoggedIn)
      return true

    // NavigationExtras queryparams/fragment preservation is ignored in an
    // auth guard; hence, they are explicitly referenced here to ensure they
    // are preserved
    this.router.navigate(['/login'], { fragment: route.fragment,
                                       queryParams: route.queryParams,
                                       replaceUrl: true });
    return false;
  }

}
