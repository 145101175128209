import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSort, MatTableDataSource, MatSortable, MatDialog } from '@angular/material';

import { SsoService, Account, AccountList } from '../sso.service'
import { SsoLoginComponent } from '../sso-login/sso-login.component'

@Component({
  selector: 'app-sso-list',
  templateUrl: './sso-list.component.html',
  styleUrls: ['./sso-list.component.css']
})
export class SsoListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  loadComplete = false
  displayedColumns = ['key', 'name', 'externalId', 'actions'];
  dataSource = new MatTableDataSource();

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog, private ssoService: SsoService) { }

  ngOnInit() {
    this.dataSource.sort = this.sort
    this.sort.sort(<MatSortable>{
       id: 'key',
       start: 'asc'
     }
   )

    this.ssoService.list()
      .then(data => Object.values(data))
      .then(data => this.dataSource.data = data)
      .then(() => this.loadComplete = true)
      .catch(e => console.error("Error", e))
  }

  ngAfterViewInit() {
    if (!!this.activatedRoute.snapshot.fragment) {
      this.ssoService.list()
        .then(data => Object.values(data))
        .then(data => {return data.find(x => x["key"] == this.activatedRoute.snapshot.fragment)})
        .then((account) => this.login(account));
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  login(account: Account) {
    console.log("Login", account)

    let dialogRef = this.dialog.open(SsoLoginComponent, {
      width: '500px',
      disableClose: true,
      data: { account: account }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
